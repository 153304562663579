import React from 'react'

import hotel1 from '../images/approach_graded_squash2.jpg'
import hotelold from '../images/approach_graded_squash.jpg'

import TableRenderer from '../components/TableRenderer'
import testjsondata from '../data/testdata.json'

import Layout from '../components/layout'

import '../assets/scss/main.scss'

class BarPrices extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return [
      <div key="one" id="header">
        <div id="pricespage">
          <h2 className="major" key="one"><a href="https://www.motelelaeropuerto.com/">Motel El Aeropuerto</a></h2>
          <span className="image main" key="two">
            <img src={hotel1} alt="" />
          </span> 
          <p key="three">Disponibles para la compra</p>
          <h3>Licores y Bebidas</h3>
          <table>
            <tbody>
              <tr>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td> Ron (Caneca) </td>
                <td class="price"> $38,000</td>
              </tr>
              <tr>
                <td> Aguardiente (Caneca) </td>
                <td class="price"> $35,000</td>
              </tr>
              <tr>
                <td> Brandy (Caneca) </td>
                <td class="price"> $35,000</td>
              </tr>
              <tr>
                <td> Vino Cariñoso </td>
                <td class="price"> $27,000</td>
              </tr>
              <tr>
                <td> Red Bull </td>
                <td class="price"> $9,000</td>
              </tr>
              <tr>
                <td> Cerveza Aguila Light </td>
                <td class="price"> 6,000</td>
              </tr>
              <tr>
                <td> Cerveza Poker o Light (Botella) </td>
                <td class="price"> $6,000</td>
              </tr>
              <tr>
                <td> Coronita </td>
                <td class="price"> $7,000</td>
              </tr>
              <tr>
                <td> Cerveza Corona </td>
                <td class="price"> $10,000</td>
              </tr>
              <tr>
                <td> Cerveza Heineken </td>
                <td class="price"> $6,000</td>
              </tr>
              <tr>
                <td> Jugo del Valle o Hit </td>
                <td class="price"> $5,000</td>
              </tr>
              <tr>
                <td> Agua </td>
                <td class="price"> $5,000</td>
              </tr>
              <tr>
                <td> Gaseosa, Malta, o Ginger Ale C/U</td>
                <td class="price"> $5,000</td>
              </tr>
              <tr>
                <td> Gatorade o Coca Cola con café </td>
                <td class="price"> $6,000</td>
              </tr>
              <tr>
                <td> Coca Cola no retornable </td>
                <td class="price"> $4,000</td>
              </tr>
              <tr>
                <td> Vive 100, Cifrut o Saviloe </td>
                <td class="price"> $3,500</td>
              </tr>
            </tbody>
          </table>
          <h3>Mecatico</h3>
          <table>
            <tbody>
              <tr>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>Salchicha Zenú</td>
                <td class="price">$6,500</td>
              </tr>
              <tr>
                <td>Papas Margarita</td>
                <td class="price">$3,500</td>
              </tr>
              <tr>
                <td>Maní Especial</td>
                <td class="price">$5,000</td>
              </tr>
              <tr>
                <td>Chicles (pequeño) </td>
                <td class="price">$3,500</td>
              </tr>
              <tr>
                <td>Chicles (grande)</td>
                <td class="price">$3,000</td>
              </tr>
              <tr>
                <td>Trident (grande)</td>
                <td class="price">$3,500</td>
              </tr>
              <tr>
                <td>Trident (pequeño)</td>
                <td class="price">$2,500</td>
              </tr>
              <tr>
                <td>Lechera</td>
                <td class="price">$2,500</td>
              </tr>
              <tr>
                <td>Halls</td>
                <td class="price">$3,000</td>
              </tr>
              <tr>
                <td>Yupis</td>
                <td class="price">$1,000</td>
              </tr>
              <tr>
                <td>Bombones y chocolatinas</td>
                <td class="price">$1,500</td>
              </tr>
            </tbody>
          </table>
          <h3>Accesorios Íntimos</h3>
          <table>
            <tbody>
              <tr>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>Preservativos</td>
                <td class="price">$5,000</td>
              </tr>
              <tr>
                <td>Toallas Higiénicas</td>
                <td class="price">$6,000</td>
              </tr>
              <tr>
                <td>Gorro de Baño</td>
                <td class="price">$1,500</td>
              </tr>
              <tr>
                <td>Baño Espumoso (adicional)</td>
                <td class="price">$1,000</td>
              </tr>
              <tr>
                <td>Peineta</td>
                <td class="price">$1,500</td>
              </tr>
              <tr>
                <td>Aspirina o Dolex</td>
                <td class="price">$1,000</td>
              </tr>
              <tr>
                <td>Bonfiest</td>
                <td class="price">$5,000</td>
              </tr>
              <tr>
                <td>Alka-setzer</td>
                <td class="price">$2,500</td>
              </tr>
              <tr>
                <td>Crema Colgate (pequeña)</td>
                <td class="price">$3,500</td>
              </tr>
              <tr>
                <td>Cepillo de dientes</td>
                <td class="price">$3,000</td>
              </tr>
              <tr>
                <td>Shampoo Savital</td>
                <td class="price">$3,500</td>
              </tr>
              <tr>
                <td>Desodorante</td>
                <td class="price">$3,500</td>
              </tr>
              <tr>
                <td>Máquina de afeitar</td>
                <td class="price">$2,500</td>
              </tr>
              <tr>
                <td>Cigarrillos Malboro</td>
                <td class="price">$18,000</td>
              </tr>
             <tr>
                <td>Encendedor</td>
                <td class="price">$2,500</td>
              </tr>
            </tbody>
          </table>
            </div>
      </div>,
    ]
  }
}

export default BarPrices
